import React, { useState,useEffect } from "react"
import Link from "gatsby-link"

import { useDispatch } from "react-redux"
import { videoModalAction } from "../../store/actions"

import { GrPlayFill } from "react-icons/all"


import * as styles from "./preview.module.scss"
import * as btnStyles from "../buttons/buttons.module.scss"

const Preview = ({
    title,
    content,
    link,
    linkType,
    linkTitle,
    sourceType,
    source,
    sourcePosition,
    sourceStyles,
    shapeStyles,
}) => {
    const dispatch = useDispatch()

    useEffect(
        ()=>{
            console.log("da Source",source)
        }
    )




    return (
        <div
            className={styles.preview}
        >
            <div className={`${styles[sourcePosition]} ${styles.row}`}>
                <div className={`${styles.col} ${styles.first}`}>
                    <h2>{title}</h2>
                    <p>{content}</p>
                    {link !== '' && (
                        linkType == "button" ? (
                            <Link
                                to={link}
                                className={`${btnStyles.button} ${btnStyles.buttonBorder}`}
                            >
                                {linkTitle}
                            </Link>
                        ) : (
                            <Link className={styles.link} to={link}>
                                — {linkTitle}
                            </Link>
                        )
                    )
                    }
                </div>
                <div className={`${styles.col} ${styles.second}`}>
                    {sourceType === "video" ? (
                        <GrPlayFill
                            className={styles.play}
                            onClick={() => dispatch(videoModalAction({ display: true, vimeo: source.vimeo }))}
                        // onClick={() => dispatch(videoModalAction("71303723"))}
                        // onClick={() => dispatch(videoModalAction("71303723"))}
                        ></GrPlayFill>
                    ) : (
                        ""
                    )}
                    <div className={styles.shape} style={shapeStyles}></div>
                    <img src={source.image} style={sourceStyles} />
                </div>
            </div>
        </div>
    )
}

export default Preview
